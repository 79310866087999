
import { useCourses } from '@/composables/courses/useCourses'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    video: {
      type: Object,
      default: () => ({})
    },
  },

  setup (props) {
    const { changeWatched } = useCourses()

    const description = computed(() => {
      const regex = /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi

      return props.video.description.replace(regex,
        (_match: any, space: any, url: any) => {
          var link = url
          if (!link.match("^https?://")) {
            link = `http://${link}`
          }
          return space + `<a href="${link}" target="_blank">${url}</a>`
        }
      )
    })

    return {
      changeWatched,
      description
    }
  }
})
