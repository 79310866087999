<template>
  <dashboard-content>
    <div
      v-for="course in courses"
      :key="course.id"
    >

      <section>
        <div
          class="section-header"
        >
          <h2>{{ course.name }}</h2>
        </div>

        <div
          class="playlist-grid"
          v-if="course.classes.length"
        >
          <video-thumbnail
            v-for="video in course.classes.slice(0, 5)"
            :key="video.id"
            :video="video"
          />
        </div>
        <div
          v-else
          class="playlist-grid"
        >
          <div
            v-for="item in 5"
            :key="item"
            class="relative card w-full h-full rounded-lg bg-gradient-to-tr from-indigo-800 via-indigo-600 to-indigo-500"
            style="padding-top: 56.25%"
          >
            <div class="absolute top-1/2 w-full flex justify-center items-center space-x-2">
              <i class="fas fa-spinner fa-spin text-white"></i>
              <div class="text-white">
                {{ $t('sentence.loading') }}
              </div>
            </div>
          </div>
        </div>

        <router-link
          class="btn btn-secondary my-8 w-max mx-auto"
          :to="{ name: 'course', params: { courseId: course.id } }"
        >
          {{ $t('page.home.section_video_action_more') }}
        </router-link>
      </section>
    </div>
  </dashboard-content>
</template>

<script lang="ts">
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import { defineComponent, onBeforeMount, watchEffect } from 'vue'
import { useCourses } from '@/composables/courses/useCourses'
import VideoThumbnail from '@/components/video/VideoThumbnail.vue'

export default defineComponent({
  components: { DashboardContent, VideoThumbnail },

  setup () {
    const {
      courses,
      loadCourses,
      loadClasses
    } = useCourses()

    onBeforeMount(() => {
      loadCourses()
    })

    watchEffect(() => {
      courses.value.map((course: { id: string }) => loadClasses(course.id))
    })

    return {
      courses
    }
  }
})
</script>

<style>
@layer components {
  .playlist-grid {
    @apply grid grid-cols-2 md:grid-cols-4 gap-4 h-full;

    div:nth-child(1) {
      @apply col-span-2 row-span-2;
    }
  }

  section {
    @apply px-4 lg:px-12 py-8 mb-12 overflow-x-hidden;
    @apply bg-white;

    .section-header {
      @apply mb-16;
      
      h2 {
        @apply text-xl;
      }
    }
  }
}
</style>