<template>
  <base-modal v-model:open="open" hide-footer size="larger">
    <template v-slot:button>
      <img
        class="card w-full h-full rounded-lg"
        :src="video.thumbnail"
      >
    </template>
    <video-player-modal :video="video" />
    <button
      class="btn btn-secondary absolute top-2 right-2"
      @click.prevent="close"
    >
      <i class="fas fa-times" style="margin: 0 !important"></i>
    </button>
  </base-modal>
</template>

<script lang="ts">
import { useToggle } from '@/composables/useToggle'
import { defineComponent } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import VideoPlayerModal from '@/components/video/VideoPlayerModal.vue'

export default defineComponent({
  components: { BaseModal, VideoPlayerModal },

  props: {
    video: {
      type: Object,
      default: () => ({})
    },
  },

  setup () {
    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    return {
      open,
      close
    }
  }
})
</script>