
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import { computed, defineComponent, onBeforeMount, watchEffect } from 'vue'
import { useCourses } from '@/composables/courses/useCourses'
import router from '@/router'
import VideoThumbnail from '@/components/video/VideoThumbnail.vue'

export default defineComponent({
  components: { DashboardContent, VideoThumbnail },

  setup () {
    const {
      courses,
      loadCourses,
      loadClasses
    } = useCourses()

    const courseId = computed(() => {
      return router.currentRoute.value.params.courseId as string
    })

    const course = computed(() => {
      return courses.value.find((course: { id: any }) => course.id === courseId.value)
    })

    const classes = computed(() => {
      if (course.value?.classes) {
        return course.value.classes
      }

      return []
    })

    onBeforeMount(() => {
      loadCourses()
      if (courseId.value) {
        loadClasses(courseId.value)
      }
    })

    watchEffect(() => {
      if (course.value) {
        loadClasses(courseId.value)
      }
    })

    return {
      classes,
      course
    }
  }
})
