<template>
  <dashboard-content>
    <section>
      <div
        class="section-header"
      >
        <h2>{{ course?.name }}</h2>
      </div>
      <div class="playlist-grid">
        <video-thumbnail
          v-for="video in classes"
          :key="video.id"
          :video="video"
        />
      </div>
    </section>
  </dashboard-content>
</template>

<script lang="ts">
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import { computed, defineComponent, onBeforeMount, watchEffect } from 'vue'
import { useCourses } from '@/composables/courses/useCourses'
import router from '@/router'
import VideoThumbnail from '@/components/video/VideoThumbnail.vue'

export default defineComponent({
  components: { DashboardContent, VideoThumbnail },

  setup () {
    const {
      courses,
      loadCourses,
      loadClasses
    } = useCourses()

    const courseId = computed(() => {
      return router.currentRoute.value.params.courseId as string
    })

    const course = computed(() => {
      return courses.value.find((course: { id: any }) => course.id === courseId.value)
    })

    const classes = computed(() => {
      if (course.value?.classes) {
        return course.value.classes
      }

      return []
    })

    onBeforeMount(() => {
      loadCourses()
      if (courseId.value) {
        loadClasses(courseId.value)
      }
    })

    watchEffect(() => {
      if (course.value) {
        loadClasses(courseId.value)
      }
    })

    return {
      classes,
      course
    }
  }
})
</script>

<style>
@layer components {
  .playlist-grid {
    @apply grid grid-cols-2 md:grid-cols-4 gap-4 h-full;

    div:nth-child(1) {
      @apply col-span-2 row-span-2;
    }
  }

  section {
    @apply px-4 lg:px-12 py-8 mb-12 overflow-x-hidden;
    @apply bg-white;

    .section-header {
      @apply mb-16;
      
      h2 {
        @apply text-xl;
      }
    }
  }
}
</style>