import { computed } from "vue"
import { useStore } from "vuex"

export const useCourses = () => {
  const store = useStore()

  const loadCourses = () => {
    const isLoaded = store.state.courses.isLoaded
    if (!isLoaded) {
      store.dispatch('courses/fetchCourses')
      store.dispatch('courses/fetchUserInteraction')
    }
  }

  const loadClasses = (courseId: string) => {
    const course = courses.value.find((course: { id: string }) => course.id === courseId)
    if (course?.classes.length) {
      return
    }
    store.dispatch('courses/fetchClasses', courseId)
  }

  const changeWatched = async (classId: string) => {
    await store.dispatch('courses/changeWatched', classId)
  }

  const courses = computed(() => {
    return store.getters['courses/courses']
  })

  return {
    loadCourses,
    loadClasses,
    changeWatched,
    courses
  }
}