<template>
  <div class="card-body pt-4 flex flex-col lg:flex-row space-y-4 lg:space-x-4 lg:space-y-0 h-full overflow-y-scroll lg:overflow-hidden">
    <div class="w-full lg:w-2/3">
      <div
        class="relative bg-cover bg-no-repeat bg-center"
        style="padding-top: 56.25%"
        :style="`background-image: url('${video.thumbnail}')`"
      >
        <iframe
          class="absolute inset-0 w-full h-full"
          :src="`https://www.youtube.com/embed/${video.id}`"
          frameborder="0"
          allowtransparency = "true"
        >
        </iframe>
      </div>
      <div class="card-header px-0">
        <div class="card-title">
          {{ video.title }}
        </div>
      </div>
      <div class="w-full flex justify-end space-x-4">
        <button
          class="btn"
          :class="{ watched: video.watched }"
          @click.prevent="changeWatched(video.id)"
        >
          <i class="fas fa-check"></i>
          Assistido
        </button>
      </div>
    </div>

    <div
      class="w-full lg:w-1/3 lg:overflow-y-scroll whitespace-pre-wrap"
      v-html="description"
    />
  </div>
</template>

<script lang="ts">
import { useCourses } from '@/composables/courses/useCourses'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    video: {
      type: Object,
      default: () => ({})
    },
  },

  setup (props) {
    const { changeWatched } = useCourses()

    const description = computed(() => {
      const regex = /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi

      return props.video.description.replace(regex,
        (_match: any, space: any, url: any) => {
          var link = url
          if (!link.match("^https?://")) {
            link = `http://${link}`
          }
          return space + `<a href="${link}" target="_blank">${url}</a>`
        }
      )
    })

    return {
      changeWatched,
      description
    }
  }
})
</script>

<style scoped>
.watched {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
</style>
