
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import { defineComponent, onBeforeMount, watchEffect } from 'vue'
import { useCourses } from '@/composables/courses/useCourses'
import VideoThumbnail from '@/components/video/VideoThumbnail.vue'

export default defineComponent({
  components: { DashboardContent, VideoThumbnail },

  setup () {
    const {
      courses,
      loadCourses,
      loadClasses
    } = useCourses()

    onBeforeMount(() => {
      loadCourses()
    })

    watchEffect(() => {
      courses.value.map((course: { id: string }) => loadClasses(course.id))
    })

    return {
      courses
    }
  }
})
