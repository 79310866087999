
import { useToggle } from '@/composables/useToggle'
import { defineComponent } from 'vue'
import BaseModal from '@/components/base/BaseModal.vue'
import VideoPlayerModal from '@/components/video/VideoPlayerModal.vue'

export default defineComponent({
  components: { BaseModal, VideoPlayerModal },

  props: {
    video: {
      type: Object,
      default: () => ({})
    },
  },

  setup () {
    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)

    return {
      open,
      close
    }
  }
})
